body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.textSliderContainer {
  display: flex;
  align-items: center;
}

button {
  background-color: #50555C;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 1vw;
  cursor: pointer;
  border-radius: 9px;
  display: inline-block;
}

input[type=range] {
  height: 26px;
  -webkit-appearance: none;
  background-color: transparent;
  margin: 10px 0;
  width: 20vw;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #50555C;
  background: #50555C;
  border-radius: 14px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 40px;
  border-radius: 12px;
  background: #529DE1;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #50555C;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #50555C;
  background: #50555C;
  border-radius: 14px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 40px;
  border-radius: 12px;
  background: #529DE1;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #50555C;
  border: 0px solid #000000;
  border-radius: 28px;
  box-shadow: 1px 1px 1px #50555C;
}
input[type=range]::-ms-fill-upper {
  background: #50555C;
  border: 0px solid #000000;
  border-radius: 28px;
  box-shadow: 1px 1px 1px #50555C;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 40px;
  border-radius: 12px;
  background: #529DE1;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #50555C;
}
input[type=range]:focus::-ms-fill-upper {
  background: #50555C;
}

@media (max-width: 1250px) {
  
}

@media only screen and (max-width: 600px) {
  input[type=range] {
    width: 65vw;
  }
}


.Rvalue::-ms-thumb {
  background: #ff0000 !important;
}

.Rvalue::-moz-range-thumb {
  background: #ff0000 !important;
}

.Rvalue::-webkit-slider-thumb {
  background: #ff0000 !important;
}

.Gvalue::-ms-thumb {
  background: #00ff00 !important;
}

.Gvalue::-moz-range-thumb {
  background: #00ff00 !important;
}

.Gvalue::-webkit-slider-thumb {
  background: #00ff00 !important;
}

.Bvalue::-ms-thumb {
  background: #0000ff !important;
}

.Bvalue::-moz-range-thumb {
  background: #0000ff !important;
}

.Bvalue::-webkit-slider-thumb {
  background: #0000ff !important;
}